<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);padding:0;">
    <div class="map" style="width: calc(100%);height: calc(65% + 20px);margin-top:50px;">
      <Map ref="map" style="width : 100%; height: 100%;" :loadMapName="loadMap" @mapping-create="createMappingLatLng" @mapping-change = "changeMappingLatLng" @mapping-add = "addMappingLatLng" @mapping-line-list="setFlightInfo"/>
      <MapButtonComponent ref="buttonMap" />
      <ul class="mp-info">
        <li>
          <span>{{ $t("mission-data-way-registrant") }}</span>
          <h4>{{ name }}</h4>
        </li>
        <li>
          <span>{{ $t("mission-data-way-dept") }}</span>
          <h4>{{ departmentName ? departmentName : "-" }}</h4>
        </li>
        <li>
          <span>{{ $t("event-data-site-phone") }}</span>
          <h4>{{ phone ? getMask(phone) : "-" }}</h4>
        </li>
      </ul>
      <div class="mp-total">
        <div class="mp-total-value" style="margin-right: 30px;">
          <h4>
            <img src="@/assets/images/ic_wp_total_time.png" alt="icon" />
            <span>{{ $t("mission-data-total-time") }}</span>
          </h4>
          <h2><span>{{viewFlightDuration}}</span></h2>
        </div>
        <div class="mp-total-value">
          <h4>
            <img src="@/assets/images/ic_wp_total_dist.png" alt="icon" />
            <span>{{ $t("mission-data-total-dist") }}</span>
          </h4>
          <h2><span>{{flightDistance}}</span>m</h2>
        </div>
      </div>
    </div>
    <div class="register" style="width: 100%;height: calc(35% - 60px);padding: 0px 30px;margin-top: 20px;display: flex;justify-content: space-between;align-items: stretch;">
      <div class="mainBox wrap-create" style="width:40%;">
        <h2 class="bold">맵핑 신규등록</h2>
        <div class="mapping-create-from">
          <div class="mapping-create-input">
            <h4>{{$t("mission-data-station")}}<span>*</span></h4>
            <select v-model="selectStation">
              <option :key="station.id" v-for="station in stationList" :value="station.device.stationDeviceId">{{station.device.deviceName}}</option>
            </select>
          </div>
          <div class="mapping-create-input">
            <h4>{{$t("mapping-data-title")}}<span>*</span></h4>
            <input type="text" v-model="title">
          </div>
          <div class="mapping-create-input">
            <h4>{{$t("mapping-data-setting-altitude")}}(m)<span>*</span></h4>
            <input type="text" v-model="altitude" placeholder="20~1000m" max="1000" min="20" @change="validateAltitude" />
          </div>
          <div class="mapping-create-input">
            <h4>{{$t("mapping-data-setting-speed")}}(m/s)</h4>
            <input type="text" v-model="autoFlightSpeed" placeholder="2~15m/s" max="15" min="2" @change="validateSpeed"/>
          </div>
          <div class="mapping-create-input" style="display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;">
            <h4>{{$t("mapping-data-setting-clear")}}</h4>
            <div class="wrap-create-radio">
              <label class="radioBtn">
                <input type="radio" name="actionType" v-model="finishedActionType" value="RTL" checked/>RTL
              </label>
              <label class="radioBtn">
                <input type="radio" name="actionType" v-model="finishedActionType" value="Hover"/>HOVER
              </label>
            </div>
          </div>
          <div class="mapping-create-input">
            <h4>{{$t("mission-data-setting-exit-on-rc-lost")}}</h4>
            <div class="wrap-create-radio">
              <label class="radioBtn">
                <input type="radio" name="exitOnRcLost" v-model="remoteSignalEndActionType" value="RTL" checked/>RTL(Return-To-Launcher)
              </label>
              <label class="radioBtn">
                <input type="radio" name="exitOnRcLost" v-model="remoteSignalEndActionType" value="GoAction"/>GoAction
              </label>
            </div>
          </div>
          <div class="mapping-create-input" style="margin-bottom: 15px;">
            <h4>{{ $t("mapping-data-camera") }}</h4>
            <select @change="selectCameraType($event)">
              <option :key="cameraType.id" v-for="cameraType in cameraTypeList" :value="cameraType.id">{{cameraType.id}}</option>
            </select>
          </div>

          <div class="mapping-create-input">
            <h4>{{ $t("mapping-data-path-angle") }}<span>*</span></h4>
            <input type="number" v-model="angle" @change="sendAngle">
          </div>

          <div class="mapping-create-input">
            <h4>{{ $t("mapping-data-path-interval") }}<span>*</span></h4>
            <input type="number" v-model="interval" @change="sendInterval">
          </div>

          <div class="mapping-create-input">
            <h4>{{ $t("mapping-data-overlab") }}</h4>
            <input type="Number" v-model="overLab" @change="checkOverLab">
          </div>

        </div>

        <div class="buttonWrap" style="display: flex; margin-top: 10px; justify-content: center;">
          <button class="point medium" style="width: 150px;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;" @click="onMappingCreate">
            {{ $t("btn-register") }}
          </button>
          <button class="medium margin6" @click="$router.push('Mapping')" style="width: 150px;height:40px; border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-cancel") }}
          </button>
        </div>

      </div>
      <div style=" width: calc(60% - 20px);background: rgb(255, 255, 255);padding: 10px;border-radius: 5px;">
        <!--드론/멤버/포인트 테이블-->
        <div class="tableWrap one">
          <div class="tableBox" style=" height: calc(35vh - 90px); overflow-y: auto;" ><!--:style="{'max-height' : (typeChangeMapShowHeight - 225) + 'px'}">-->
            <table>
              <colgroup>
                <col style="width: 20px">
                <col style="width: 10px">
                <col style="width: 20%">
                <col style="width: 20%">
                <col style="width: 10%">
                <col style="width: 10%">
              </colgroup>
              <tr>
                <th class="fixedHeader">{{ $t("mission-data-setting-remark") }}</th>
                <th class="fixedHeader" style=" padding: 6px 15px; font-size: 1.3rem; font-weight: bold; background: #f4f4f4;">No.</th>
                <th class="fixedHeader">{{ $t("mission-data-lat") }}</th><!--위도-->
                <th class="fixedHeader">{{ $t("mission-data-long") }}</th><!--경도-->
                <th class="fixedHeader">{{ $t("mission-data-alt") }}</th><!--고도-->
                <th class="fixedHeader">{{ $t("mission-data-vel") }}</th><!--속도-->
              </tr>
              <tr v-for="data in mappingLocationList " :key="data.index" @click="handleSelectEl(data)" :class="{ active: (selectData != null &&  (selectData.index ==  data.index))}">
                <td style="padding: 0px" @click="$event.cancelBubble = true">
                  <button class="small" @click="mappingLocationDelete(data)" style="border: 1px solid #ddd; color:#969696;">
                    {{ $t("btn-delete") }}
                  </button>
                </td>
                <td>{{ data.index + 1 }}</td>
                <td>{{ data.lat.toFixed(7) }}</td>
                <td>{{ data.lng.toFixed(7) }}</td>
                <td>{{ altitude }}</td>
                <td>{{ autoFlightSpeed }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isModalOpen" class="modal-mask">
      <AutoFlightLiabilityModal
          @isShowModal="isShowModal"
          @sendAllow="saveMapping"
      >
      </AutoFlightLiabilityModal>
    </div>
  </div>
</template>
<style>
.title{display: none;}
.radioBtn{
  width: auto;
  font-size: 1.2rem;
}
.wrap-create{border-radius: 5px;padding: 15px;width: 45%;}
.radioBtn:nth-child(2){
  margin-left: 20px;
}
.mapping-input{display: flex;align-items: center;margin-bottom: 15px;}
.mapping-input > label{width: 100px;}
.mapping-input > input{width: 350px !important;}

.mp-info{display: flex;margin: 0;position: absolute;top: 15px;left: 30px;align-items: center;background: #ffffff;padding: 15px;border-radius: 5px;}
.mp-info > li{display: flex;justify-content: space-between;align-items: center;border: 0;padding: 0;margin: 0;margin-right: 30px;}
.mp-info > li > span{background: #eee;width: auto;text-align: center;padding: 3px;font-size: 1.2rem;border-radius: 10px;color: #969696;padding: 0 10px;}
.mp-info > li > h4{font-size: 1.2rem;font-weight: 500;color: #454545;margin-left: 10px;}

.mapping-create-from{padding: 15px 0;height: calc(90% - 60px);overflow-y: scroll;border-top: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.mapping-create-input{width: 100%;margin-bottom: 10px;display: flex;align-items: center;justify-content: space-between;padding-right: 10px;}
.mapping-create-input > h4{font-size: 1.2rem;color: #969696;font-weight: 500;margin-bottom: 5px;}
.mapping-create-input > h4 > span{color: #f00;margin-left: 3px;}
.mapping-create-input > input{width: 80%;border-radius: 5px;height: 30px;margin-bottom: 0;}
.mapping-create-input > input::placeholder{color: #cecece;}
.mapping-create-input > label{width: 80%;display: block;font-size: 1.2rem;margin-bottom: 15px;}
.mapping-create-input > label:nth-child(2){padding-top: 10px;}
.mapping-create-input > label:nth-child(3){margin-left: 0;margin-bottom: 25px;}
.mapping-create-input > select{width: 80%;border-radius: 5px;height: 30px;}

.mapping-create-from > .mapping-create-input:last-child{margin-bottom: 0;}
.m-b-0{margin-bottom: 0 !important;}


.wrap-create-radio{display: flex;justify-content: flex-start;width: 80%;}

.mp-total{position: absolute;top: 15px;left: 50%;transform: translate(-50%, 0%);background: #ffffff;display: flex;border-radius: 3px;padding: 16px;}
.mp-total-value{display: flex;align-items: center;}
.mp-total-value > h4{display: flex;align-items: center;}
.mp-total-value > h4 > img{height: 16px;margin-right: 5px;}
.mp-total-value > h4 > span{color: #454545;font-size: 1.4rem;font-weight: 400;}
.mp-total-value > h2{margin-bottom: 0;font-size: 1.6rem;margin-left: 20px;}
.mp-total-value > h2 > span{font-size: 1.6rem;font-weight: 500;}
</style>
<script>
import {mapState} from "vuex";
import moment from "moment";
import Map from "@/components/map/Map";
import AutoFlightLiabilityModal from "@/components/mission/modal/AutoFlightLiabilityModal"

import {fetchEvent} from "@/api/event";
import {format} from "@/mixins/format";
import {fetchMissionCreate , getMissionCameraList} from "@/api/mission";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import MapUtils from "@/components/common/MapUtils"
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapMappingData from "@/components/map/data/MapMappingData";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";

import {getDefaultMapZoomLevel} from "@/util/common";
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
  props: ["eventId", "waypointId"],
  mixins: [format],
  components: {Map, MapButtonComponent,AutoFlightLiabilityModal},
  computed: {
    ...mapState({
      name: state => state.store.name,
      accountId: state => state.store.accountId,
      loginId: state => state.store.loginId,
      ownerWork: state => state.store.ownerWork,
      phone: state => state.store.phone,
      departmentName: state => state.store.departmentName,
      currentLang: state => state.store.currentLang,
    }),
  },
  data() {
    return {
      mappingData : new MapMappingData(),
      MapUtils : new MapUtils(),
      moment: moment,
      mappingId: "",
      eventInfo: {},
      mapHeight: 0,
      title: null,
      loadMap : 'kakao',
      autoFlightSpeed: 0,
      altitude: 0, //추후 화면에서 데잍터입력받은값으로 처리할것.
      finishedActionType: "RTL",
      remoteSignalEndActionType : "RTL",
      polygonType : "polygon",
      mappingType : "Orthophoto",
      mappingPolyLineList: [],
      mappingLocationList : [],
      mappingLocationTotal: 0,
      polygonPosList : [],
      isMakedPolygon : false,

      devPositionHeight: 122,
      typeChangeMapShowHeight: 0,
      selectEl: null,
      selectData: null,
      selectGimbalType : "Gimbal90",

      cameraTypeList : null ,
      cameraType : null,
      angle : 0 ,
      interval : 0,
      overLab : 0,
      lat  : 0,
      lng : 0,
      polygonPath : [],
      isCreated : false,
      stationList : null,
      selectStation : null,
      autoSpeedRange : {
        max : 15,
        min : 2
      },
      altitudeRange : {
        max : 1000,
        min : 20
      },
      isModalOpen : false,
      flightDistance : 0, //드론비행거리
      flightDuration : 0, //드론비행시간
      viewFlightDuration : "00:00", //00:00 식으로 나타내기 위함.
      mapUtils: new MapUtils(),
    };
  },
  mounted() {
    this.getEvent();
    this.cameraInit();
    this.getStationList();
    //this.mappingInit();
  },
  created() {
    this.mapHeight = window.innerHeight - this.devPositionHeight;
    this.typeChangeMapShowHeight = this.mapHeight * 0.6485;
    window.addEventListener('resize', this.handleResize);

    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 10 });
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    selectStation(newVal , oldVal){
      this.setStationMapMarker(newVal, oldVal)
      this.setFlightDistance( this.mappingPolyLineList,newVal)
      this.setFlightDuration( this.mappingPolyLineList,newVal)
    },
    autoFlightSpeed(){
      this.setFlightDistance( this.mappingPolyLineList,this.selectStation)
      this.setFlightDuration( this.mappingPolyLineList,this.selectStation)
    },
  },
  methods: {

    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function (){
        this.setMapSetting();
        this.setMapEventCenter();
      })
    },
     sendAngle(){//매핑 각도가 변경되었을 경우.
      //const numberOnly = this.angle.replace("/[^0-9]/g", "");
      const numberOnly = Math.abs(this.angle.replace(/\D/g, ''));
      this.angle = numberOnly%360
      this.mappingData.angle = this.angle
     // this.$refs.map.sendAngle(this.angle);
      this.createMappingLine(this.mappingData.type.angle)
    },
    sendInterval(){
      const numberOnly = Math.abs(this.interval.replace(/\D/g, ''));
      this.interval = numberOnly%100
      this.mappingData.interval = this.interval
      //this.$refs.map.sendInterval(this.interval)
      console.log("sendInterval" , this.interval)
      this.createMappingLine(this.mappingData.type.interval)
    },
    sendDroneHight(){
      this.mappingData.altitude = this.altitude
     // this.$refs.map.sendAltitude(this.altitude)
      this.createMappingLine(this.mappingData.type.altitude)
    },
    validateAltitude() {
      const min = this.altitudeRange.min;
      const max = this.altitudeRange.max;
      const value = parseFloat(this.altitude);
      let isCanSend = false
      if (isNaN(value)) {
        this.altitudeWarning = '숫자를 입력하세요.';
        this.altitude = '';
      } else if (value < min) {
        this.altitudeWarning = `고도는 최소 ${min}m이어야 합니다.`;
        this.altitude= min;
        isCanSend = true
      } else if (value > max) {
        this.altitudeWarning = `고도는 최대 ${max}m이어야 합니다.`;
        this.altitude = max;
        isCanSend= true
      } else {
        isCanSend= true
        this.altitudeWarning = '';
      }
      if(isCanSend) this.sendDroneHight()
    },
    validateSpeed() {
      const min = this.autoSpeedRange.min;
      const max = this.autoSpeedRange.max;
      const value = parseFloat(this.autoFlightSpeed);
      if (isNaN(value)) {
        this.speedWarning = '숫자를 입력하세요.';
        this.autoFlightSpeed = '';
      } else if (value < min) {
        this.speedWarning = `속도는 최소 ${min}m/s이어야 합니다.`;
        this.autoFlightSpeed = min;
      } else if (value > max) {
        this.speedWarning = `속도는 최대 ${max}m/s이어야 합니다.`;
        this.autoFlightSpeed = max;
      } else {
        this.speedWarning = '';
      }
    },
    checkOverLab(){
      const numberOnly = Math.abs(this.overLab.replace(/\D/g, ''));
      this.overLab = numberOnly%100
      this.mappingData.overLab = this.overLab
    },
    sendMappingData(dataType){
      this.$refs.map.sendMappingData(this.mappingData,dataType)
    },
    createMappingLine(dataType){
      /**
       *  1. 각도 , 간격, 비행높이 정보를 보낸다.
       *  2. 매핑 뒤에 둘 히든 폴리곤을 생성한다.
       *    3. 히든폴리곤과 다각형에 라인을 생성한다.
       */
      this.sendMappingData(dataType);

       /**mappingType은 정사영상이냐, 방제용이냐*/
      this.createPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
      this.$refs.map.onCreateMappingLine(this.mappingType , this.polygonType)
    },

    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.memo = this.eventInfo.memo;
          if (this.eventInfo.status == "Close") {
            const elapsedMSec = this.eventInfo.closeDate.getTime() - this.eventInfo.openDate.getTime()
            this.elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
          }
          this.initMapData(this.currentLang );
        }
      }).catch(err =>{
        console.error(err)
      });
    },

    setMapEventCenter(){
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if (this.eventInfo.lng == null || this.eventInfo.lng == "" && this.eventInfo.lat == null || this.eventInfo.lat == "") {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      } else {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      }
    },

    setMapSetting(){
      this.$refs.map.isShowLine = true;
      this.$refs.map.onSetIsPolylineClick();
      this.$refs.map.onSetIsMarkerMove();
      this.$refs.map.onSetIsWindowInfo(MapDataType.ID_MAPPING);
      this.$refs.buttonMap.showMapTypeChange();
    },
    handleSelectEl(data) {
      var id = data.index;
      if (this.selectEl == id) {
        this.selectEl = null;
        this.selectData = null;
      } else {
        this.selectEl = id;
        this.selectData = data;
      }
      if (this.selectData != null) {
        this.$refs.map.onLngLatToMoveCenter(this.selectData.lng, this.selectData.lat)
      }
    },
    /**매핑 영역 밑에 깔리는 폴릭곤 */
    createSqurePolygon(point,distance,angle){
      var polygonPosList = []

      var rightPoint = this.MapUtils.calculateNewPosition(point,90+angle,distance);
       polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint,0+angle,distance,polygonPosList.length));
      polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint,180+angle,distance,polygonPosList.length));


      var leftPoint = this.MapUtils.calculateNewPosition(point,270+angle,distance);
       polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint,180+angle,distance,polygonPosList.length));
      polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint,0+angle,distance,polygonPosList.length));

      return polygonPosList
    },

    createMappingLatLng(latlng){
      this.createPolygonMarker(latlng)
    },

    onMappingCreate() { //api 데이터 저장
      if(this.title == null || this.title.trim() == "") {
        alert(this.$t("alert-message-check-mission-title"));
        return;
      }

      if(this.altitude == null || this.altitude.toString().trim() == "") {
        alert(this.$t("alert-message-check-mission-height"));
        return;
      }
      if(this.autoFlightSpeed == null || this.autoFlightSpeed.toString().trim() == "") {
        alert(this.$t("alert-message-check-mission-speed"));
        return;
      }

      if(this.mappingLocationList.length < 3){
        alert(this.$t("alert-message-check-mission-polygon"));
        return
      }

      if (!this.checkAutoFlightSpeed(this.autoFlightSpeed)) {
        alert(this.$t("alert-message-check-mission-speed-value"));
        return
      }
      if (!this.checkAltitude(this.altitude)) {
        alert(this.$t("alert-message-check-mission-altitude-value"));
        return
      }

      this.isShowModal(true)
    },
    saveMapping(allow){
      if(!allow) return

      let center = this.createCenterInfo()
      let data = {
        autoFlightSpeed: this.autoFlightSpeed, //속도
        altitude: this.altitude, //높이
        eventId : this.eventId,
        title : this.title, //제목
        finishedActionType : this.finishedActionType, //비행 종료후
        angle : this.angle, //경로 각도
        interval : this.interval , //경로 간격
        cameraType : this.cameraType.id, //카메라 타입
        overLab : this.overLab, //오버랩
        locations : this.mappingLocationList, //폴리곤 애만 하기.
        lat : center.lat,  //센터 lat 값
        lng : center.lng,  //센터 lng 값

        missionRefType : "Drone",
        missionType : "Mapping",
        refId : this.eventId, //현장ID
        remoteSignalEndActionType : this.remoteSignalEndActionType,
      }

      if(this.selectStation != null){
        data.missionRefType = "Station"
        data.refId = this.selectStation
      }

      fetchMissionCreate(data).then((res) => {
        if (res.data.result == 0) {
          alert("매핑 등록 성공했습니다.");
          this.$router.push('Mapping')
        } else {
          if(res.data.message == "waypoint title is already") {
            alert("매핑 제목이 중복 되었습니다.")
          }
        }
      }).catch(err =>{
        console.error(err)
      })
    },

    //페이지 리사이즈
    handleResize() {
      this.mapHeight = window.innerHeight - this.devPositionHeight;
      this.typeChangeMapShowHeight = this.mapHeight * 0.5625;
    },
    backToMissionList() {
      this.$emit("back-list")
    },
    selectCameraType(event){
      var findIndex = this.getCameraListId(event.target.value);
      if(findIndex != -1){
        var camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
        this.cameraType = camera
        this.mappingData.cameraType = this.cameraType

        this.createMappingLine()
      }
    },

    getCameraListId(id){
      return this.cameraTypeList.findIndex(item => {return item.id == id})
    },

    cameraInit(){
      getMissionCameraList().then(res => {
        var data = JSON.parse(res.data.data)
        console.log(data)
        this.cameraTypeList = data.DJI
        //console.log(res);
      }).then(()=>{
        this.mappingInit()
      })
    },

    mappingInit(){
      if(this.cameraTypeList.length > 0){
        this.cameraType = this.cameraTypeList[0]
        this.mappingData.cameraType = this.cameraType
        console.log(this.cameraType)
        this.createMappingLine()
      }
    },

    /**
     * n각형 만들때 사용
     */
    createPolygonMarker(latlng){
      var locationData = {
        index: this.mappingLocationList.length,
        altitude: this.altitude,
        autoFlightSpeed: this.autoFlightSpeed,
        lat: latlng.lat,
        lng: latlng.lng,
        gimbalType : this.selectGimbalType,
      }

      this.mappingLocationList.push(locationData);
      let mapDataType = MapDataType.ID_MAPPING_MARKER
      let index = this.mappingLocationList.length.toString();
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = index;
      mapMarkerData.lng = locationData.lng;
      mapMarkerData.lat = locationData.lat;
      mapMarkerData.rotate = 0;
      mapMarkerData.name = index;

      if (this.$refs.map.onIsMarker(mapDataType, index)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }

      if (this.mappingLocationList.length > 1) {
        let slice = this.mappingLocationList.slice(this.mappingLocationList.length - 2, this.mappingLocationList.length);
        this.createMapLine(this.mappingLocationList.length - 1, slice);
      }


      this.createLastLine()
    },

    /**매핑 영역안에 라인 만들때 사용 */
    createMapLine(id, locationGroup) {

      let mapDataType = MapDataType.ID_MAPPING_POLYGON_LINE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },

    changeMappingLatLng(index,latlng){
      this.mappingLocationList[index].lat = latlng.lat;
      this.mappingLocationList[index].lng = latlng.lng;
      this.createMappingLine()
    },

    // ??
    addMappingLatLng(index, latlng){
      var locationData = {
        index: index,
        altitude: this.altitude,
        autoFlightSpeed: this.autoFlightSpeed,
        lat: latlng.lat,
        lng: latlng.lng
      }

      // 기존 마커 제거
      this.mappingLocationList.splice(index, 0, locationData);

      // 삭제후 기존마커 index 재정렬
      for (let i = 0; i < this.mappingLocationList.length; i++) {
        this.mappingLocationList[i].index = i;
      }

      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = this.mappingLocationList.length;
      mapMarkerData.lng = locationData.lng;
      mapMarkerData.lat = locationData.lat;
      mapMarkerData.rotate = 0;
      mapMarkerData.name = index;
      let mapDataType = MapDataType.ID_MAPPING_MARKER;
      this.$refs.map.onCreateMarkerIndex(mapDataType, mapMarkerData, index);
      this.$refs.map.onMappingMarkerIdSort();

      // 내 좌표기준, 앞뒤 loactionData
      let slice = this.mappingLocationList.slice(index - 1, Number(index) + 1);

      var mapLineData = new MapLineData();
      mapLineData.id = index;
      mapLineData.locationGroup = slice;
      mapLineData.isMain = true;
      mapLineData.isShow = true;

      this.$refs.map.onCreateLineIndex(MapDataType.ID_MAPPING_POLYGON_LINE, mapLineData, index);
      this.$refs.map.onMappingLineIdSort();

      /**index와 length-1값이 같은 경우는 마지막 라인에 추가한것이므로 다시 닫아줘야함. */
      if(parseInt(index) == parseInt (this.mappingLocationList.length-1)){
        this.createLastLine()
      }
    },

    mappingLocationDelete(data) {
      let result = confirm("매핑포인트" + (data.index + 1) + "번을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }

      /**도형이라면 마지막 라인을 지운다. */
      if( this.mappingLocationList.length >2){
        this.$refs.map.onDeleteLineAt(MapDataType.ID_MAPPING_POLYGON_LINE , this.mappingLocationList.length);
      }

      this.mappingLocationList.splice(data.index, 1);
      for (var i = 0; i < this.mappingLocationList.length; i++) {
        this.mappingLocationList[i].index = i;
      }
      this.$refs.map.onDeleteMarkerIndex(MapDataType.ID_MAPPING_MARKER, data.index);
      this.$refs.map.onMappingMarkerIdSort();
      this.$refs.map.onMappingLineIdSort();
      this.$refs.map.onDeleteMappingLineList(MapDataType.ID_MAPPING_POLYLINE)
      /**도형을 이룰 수 있다면 첫번째와 맨마지막을 연결해준다. */
      this.createLastLine()
    },

    /**첫번째와 마지막 연결 */
    createLastLine(){
      if(this.mappingLocationList.length > 2){
        let slice = [
          this.mappingLocationList[0], // 첫 번째 요소
          this.mappingLocationList[this.mappingLocationList.length - 1] // 마지막 요소
        ];
        this.createMapLine(this.mappingLocationList.length, slice);
        this.createMappingLine()
      }
    },

     /**폴리곤 생성.*/
    createPolygon(mapDataType){

      var mapPolygonDate = new MapPolygonData();
      if(mapDataType == MapDataType.ID_MAPPING_HIDDEN_POLYGON){
        var centerInfo = this.createCenterInfo()
        mapPolygonDate.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
        mapPolygonDate.setLatLng(centerInfo)
        mapPolygonDate.posList = this.createSqurePolygon(centerInfo ,centerInfo.distance,Number(this.mappingData.angle))
        mapPolygonDate.line = mapPolygonDate.posList
      }
      this.$refs.map.onCreateMappingPolygon(mapPolygonDate,mapDataType);
    },

    /**다각형 도형의 가운데 정보를 생성한다.*/
    createCenterInfo(){
      var maxDistance = 0
      var center = this.MapUtils.calculateCentroid(this.mappingLocationList) //다각형 중점 구하기

      this.mappingLocationList.forEach(item => { //다각형 중점에서부터의 각포인트 최대 거리 구하기.
       var distance = this.MapUtils.calculateDistance(item , center)
        if(maxDistance < distance) maxDistance = distance
      })
      var data = {lat : center.lat , lng : center.lng , distance : maxDistance}
      return data
    },

    getStationList() {
      /**
       * 화면에 보여주는건 deviceName
       * 보낼때는 stationDeviceId를 전달
       */
        const params = {
            eventId: this.eventId
        }
        var tempList = []
        fetchStationDeviceDockList(params).then((res) => {
            console.log(res)
            if (res.status == 200) {
              tempList = res.data.data.content
                // .sort((a, b) => (a?.managerData?.tag) - (b?.managerData?.tag))
                // console.log(this.station)
                console.log(res.data.data.content)

            }
        }).catch(err => {
          console.error(err);
        }).finally(()=>{
          var data = {
            "device" : {stationDeviceId : null,deviceName : "None"}
          }
          tempList.unshift(data)
          this.stationList = tempList
          this.selectStation = data.device.stationDeviceId
        });
        // 응답오고 지도상에 표시
    },

    isShowModal(isShow){
      this.isModalOpen = isShow
    },

    setStationMapMarker(id, oldId) {
      const mapDataType = MapDataType.ID_SCHEDULE_STATION
      // const index = Number(item.device.managerData.stationDeviceId)
      const mapMarkerData = new MapMarkerData();


      if(id === null || id !== oldId) {
        this.$refs.map.onDeleteMarkerList(mapDataType)
      }
      if(id === null) return;

      let stationDeviceIndex = this.stationList.findIndex(item => item.device.stationDeviceId == id);
      if (stationDeviceIndex != -1) {
        let stationDeviceInfo = this.stationList[stationDeviceIndex];
        mapMarkerData.id = stationDeviceInfo.device.stationDeviceId;
        mapMarkerData.name = stationDeviceInfo.device.deviceName;
        mapMarkerData.isMain = true
        let lng = stationDeviceInfo.device.managerData.lng
        let lat = stationDeviceInfo.device.managerData.lat
        if (lng == null || lat == null) {
          return;
        }
        mapMarkerData.lng = stationDeviceInfo.device.managerData.lng;
        mapMarkerData.lat = stationDeviceInfo.device.managerData.lat;
        this.$refs.map.onLngLatToMoveCenter(lng, lat)

        if (this.$refs.map.onIsMarker(mapDataType, stationDeviceInfo.device.stationDeviceId)) {
          this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
        } else {
          this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
        }
        // this.$refs.map.onMappingMarkerIdSort();
        // this.$refs.map.onMappingLineIdSort();
      }
    },

    setFlightInfo(mappingLineList){
      this.mappingPolyLineList = mappingLineList
      console.log("mappingLineList", mappingLineList)
      this.setFlightDistance( this.mappingPolyLineList,this.selectStation)
      this.setFlightDuration( this.mappingPolyLineList,this.selectStation)

    },
    /**
     * 마커의 위치가 변경되면 시간 및 거리가 변경된다.
     * 마커의 속도가 바뀌면 시간이 변경된다.
     * */
    setFlightDistance(wayLineList,stationId){
      this.flightDistance = 0
      if(wayLineList.length <= 1) return

      if(stationId != null){ //station이 존재한다.
        let stationDeviceIndex = this.stationList.findIndex(item => item.device.stationDeviceId == stationId);
        //스테이션에서 첫번째와 마지막 거리 계산.
        if(stationDeviceIndex != -1) {
          let stationDeviceInfo = this.stationList[stationDeviceIndex];
          const endIndex = wayLineList.length-1
          const stationPoint = {lat :stationDeviceInfo.device.managerData.lat , lng : stationDeviceInfo.device.managerData.lng}
          const firstPoint = {lat : wayLineList[0].lat , lng : wayLineList[0].lng}
          const lastPoint = {lat : wayLineList[endIndex].lat , lng : wayLineList[endIndex].lng}
          const startDistance = this.mapUtils.calculateDistance(stationPoint, firstPoint)
          const endDistance = this.mapUtils.calculateDistance(stationPoint, lastPoint)
          this.flightDistance = this.flightDistance+startDistance+endDistance
        }
      }
      for(var i = 1;i<wayLineList.length; i++ ){
        const prevPoint = {lat : wayLineList[i-1].lat , lng : wayLineList[i-1].lng}
        const nowPoint = {lat : wayLineList[i].lat , lng : wayLineList[i].lng}
        const distance = this.mapUtils.calculateDistance(prevPoint, nowPoint)
        this.flightDistance = this.flightDistance+distance
      }
      this.flightDistance = parseInt(this.flightDistance)
    },
    setFlightDuration(wayLineList,stationId){
      this.flightDuration = 0
      if(stationId == null && wayLineList.length < 1) return; //스테이션이 없고 라인이 1보다 작으면 리턴
      if(stationId !=null && this.autoSpeedRange.min <= this.autoFlightSpeed ){ //스테이션에서부터 첫번째와 마지막의 시간 계산
        let stationDeviceIndex = this.stationList.findIndex(item => item.device.stationDeviceId == stationId);
        if(stationDeviceIndex != -1) {
          let stationDeviceInfo = this.stationList[stationDeviceIndex];
          const endIndex = wayLineList.length-1
          const stationPoint = {lat :stationDeviceInfo.device.managerData.lat , lng : stationDeviceInfo.device.managerData.lng}
          const firstPoint = {lat : wayLineList[0].lat , lng : wayLineList[0].lng}
          const lastPoint = {lat : wayLineList[endIndex].lat , lng : wayLineList[endIndex].lng}
          const startDistance = this.mapUtils.calculateDistance(stationPoint, firstPoint)
          const endDistance = this.mapUtils.calculateDistance(stationPoint, lastPoint)
          this.flightDuration =  (startDistance/this.autoFlightSpeed) + (endDistance/this.autoFlightSpeed)
        }
      }
      for(var i = 1;i<wayLineList.length; i++ ){
        const targetSpeed = this.autoFlightSpeed
        if(targetSpeed < this.autoSpeedRange.min) continue
        const prevPoint = {lat : wayLineList[i-1].lat , lng : wayLineList[i-1].lng}
        const nowPoint = {lat : wayLineList[i].lat , lng : wayLineList[i].lng}
        const distance = this.mapUtils.calculateDistance(prevPoint, nowPoint)
        const secondTime = distance/ targetSpeed
        this.flightDuration = this.flightDuration + secondTime
      }
      const minutes = Math.floor(this.flightDuration / 60);
      const seconds = Math.floor(this.flightDuration % 60);
      // 두 자리로 포맷팅
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      this.viewFlightDuration = `${formattedMinutes}:${formattedSeconds}`;
    },
    checkAutoFlightSpeed(autoFlightSpeed) {
      if (autoFlightSpeed == null || autoFlightSpeed.toString().trim() == "") {
        return false
      }
      var temp = parseInt(autoFlightSpeed)
      if (1 < temp && temp < 16) return true

      return false
    },
    checkAltitude(altitude) {
      if (altitude == null || altitude.toString().trim() == "") {
        return false
      }
      var temp = parseInt(altitude)
      if (19 < temp && temp < 1001) return true

      return false
    },
  },

  // 삭제는 SDFile delete fileId 사용
};
</script>
<style scoped="scoped">
.fixedHeader {
  position: sticky;
  top: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
